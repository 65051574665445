import { useState, useEffect } from 'react';
import { useAuth } from './auth';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import config from "../config/config";

const admin_url = `${config.apiBaseUrl}get/admin`;

const SuperAdmin = ({ children }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [admin, setAdmin] = useState({});
  const [loading, setLoading] = useState(true); // Add a loading state

  const fetchAdmin = async () => {
    try {
      const res = await axios.get(admin_url, {
        headers: {
          authorization: auth.user.token,
        },
      });

      const data = res.data;

      if (data) {
        setAdmin(data);
        console.log(data);
      }
    } catch (error) {
      toast.error("Failed to fetch admin details");
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  };

  useEffect(() => {
    if (auth.user && auth.user.token) { // Check if auth.user is available and has a token
      fetchAdmin();
    } else {
      setLoading(false); // Set loading to false if auth.user is not available
    }
  }, [auth.user]);

  if (loading) {
    return null; // Or a loading spinner if you have one
  }

  if (admin.role !== "super_admin") {
    auth.logout();
    navigate('/login'); 
    return null;
  }

  return children;
};

export default SuperAdmin;
