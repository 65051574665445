import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

function Modal({ isVisible, onClose, children }) {
  if (!isVisible) return null;

  const handleClose = (e) => {
    if (e.target.id === "wrapper" && onClose) onClose();
    e.stopPropagation();
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm"
      id="wrapper"
      onClick={handleClose}
    >
      <div className="relative w-full max-w-lg p-6 bg-white rounded-lg shadow-lg transition-transform transform duration-300 ease-out scale-95">
        {onClose && (
          <button
            onClick={onClose}
            className="absolute top-3 right-3 text-gray-600 hover:text-gray-900 focus:outline-none"
          >
            <FontAwesomeIcon icon={faClose} className="text-2xl" />
          </button>
        )}
        <div className="p-4">
          {children}
        </div>
      </div>
    </div>
  );
}

export default Modal;
