const config = {
   // apiBaseUrl: 'https://backend.phishinstinct.com/'
   // apiBaseUrl: 'http://localhost:8000/'
   apiBaseUrl:"https://app.phishinstinct.com/api/"

   
  
  };
  
  export default config;

  
