import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faUserPlus,
  faFileCsv,
  faCircleArrowLeft,
  faCircleArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../utils/auth";
import axios from "axios";
import Modal from "../components/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../config/config";
import { useNavigate } from "react-router-dom";

const USERS_URL = `${config.apiBaseUrl}adminroute/admins`;
const CREATE_URL = `${config.apiBaseUrl}user/createUsers`;
const DELETE_URL = `${config.apiBaseUrl}user/deleteUsers`;
const DELETE_ALL_URL = `${config.apiBaseUrl}user/deleteAllUsers`;
const CSV_USERS_URL = `${config.apiBaseUrl}user/addUsers`;
const admin_url = `${config.apiBaseUrl}get/admin`


//UserTable component
function AdminList() {
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [csvModal, setCsvModal] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [chunkId, setChunkId] = useState(1);
  const [chunkSize, setChunkSize] = useState(1);
  const auth = useAuth();
  const [userCount, setUserCount] = useState(0);
  const [admin, setAdmin] = useState({})
  const navigate = useNavigate()
  

  const fetchAdmin = async () => {
    try {
      const res = await axios.get(admin_url, {
        headers: {
          authorization: auth.user.token,
        },
      });
      const data = res.data;
      if (data) {
        setAdmin(data);
       
      }
    } catch (e) {
      console.log(e);
    }
  };

  //initialization call
  const fetchUser = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: USERS_URL,
        headers: {
          authorization: auth.user.token,
        }
      });
      const data = res.data;
      if (data.pages !== -1) {
        setChunkSize(data.pages);
      }
      setUsers(res.data);
      setUserCount(data.count);
    } catch (e) {
      toast.error(e.message || 'An Error Occured , Please Try Again');
    }
  };

  useEffect(() => {
    fetchAdmin();
  }, []);

  useEffect(() => {
    if (admin) { // Only call fetchUser if admin is set
      fetchUser();
    }
    setCsvModal(null);
  }, [chunkId, admin]); // Add admin as a dependency


  //







  const handleAdminDelete = async (adminId) => {
    if (window.confirm("Are you sure you want to delete this admin? This action is non-reversible.")) {
      try {
        const response = await axios.delete(`${config.apiBaseUrl}adminroute/admin/${adminId}`, {
          headers: {
            authorization: auth.user.token,
          },
        });
        if (response.status === 200) {
          toast.success("Admin deleted successfully!");
          fetchUser(); // Refresh the user list after deletion
        }
      } catch (error) {
        toast.error(error.message || "An error occurred. Please try again.");
      }
    }
  };
  


  const handleAdminClick = (adminId) =>{
    navigate(`/admin/${adminId}/users`)
  }
  return (
    <>
      <div className="px-6 py-0 my-4">
        <div className="w-full place-center relative overflow-x-auto shadow-md sm:rounded-lg content-center m-2">
          <div className="flex flex-row-reverse space-x-3 items-center">
            <div className="px-3 ml-auto">
            
            </div>
            <div className="px-3">
              {/* <button onClick={() => setShowModal(true)}>
                <FontAwesomeIcon icon={faUserPlus} size="2xl" />
              </button> */}
            </div>
            <div className="px-3">
              {/* <button onClick={() => setCsvModal(true)}>
                <FontAwesomeIcon icon={faFileCsv} size="2xl" />
              </button>  */}
            </div> 


          </div>
          <table className="w-full text-sm text-left text-gray-500 m-2 p-2">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
               
               
                <th scope="col" className="px-4 py-3">
                  Name
                </th>
                <th scope="col" className="px-4 py-3">
                  Email
                </th>
                <th scope="col" className="px-4 py-3">
                  UserLimit
                </th>
                <th scope="col" className="px-4 py-3">
                  Role
                </th>
                {/* <th scope="col" className="px-4 py-3">
                                Group
                            </th> */}
                <th scope="col" className="px-4 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {/* <UserData users={users}/> */}
              {users.map((curUser) => {
                return (
                  <tr
                    key={curUser.id}
                    className="bg-white border-b hover:bg-gray-50"
                  >
                
                    <td  onClick={()=>handleAdminClick(curUser.adminId)} className="px-3 py-4">{curUser.adminName}</td>
                    <td className="px-3 py-4">{curUser.adminEmail}</td>
                    <td className="px-3 py-4">{curUser.userLimit}</td>
                    <td className="px-3 py-4">{curUser.role}</td>
                    {/* <td className="px-3 py-4">{curUser.group}</td> */}
                    <td className="px-7 py-4">
                      <button onClick={() => handleAdminDelete(curUser.adminId)}>
                        <FontAwesomeIcon icon={faTrashCan} size="xl" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>       
      </div>
    </>
  );
}

export default AdminList;
