import React, { useEffect, useRef } from "react";

const Loader = ({onVideoEnd}) => {
    const videoRef = useRef(null);

    useEffect(() => {
      const handleVideoEnd = () => {
        onVideoEnd();
      };
  
      const videoElement = videoRef.current;
      videoElement.addEventListener("ended", handleVideoEnd);
  
      return () => {
        videoElement.removeEventListener("ended", handleVideoEnd);
      };
    }, [onVideoEnd]);
  
    return (
      <div className="fixed inset-0 bg-black flex items-center justify-center z-50">
        <video ref={videoRef} autoPlay muted className="w-full h-full object-cover">
          <source src="./loadingVideo.mp4" autoPlay muted preload type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  }

export default Loader