import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'

const ExcelFile = () => {
    const navigate = useNavigate();
   useEffect(()=>{

    const downloadFile = ()=>{
    const link = document.createElement('a');
    link.href= './download.xlsx'
    link.setAttribute('download','DemoFile.xlsx')
    document.body.appendChild(link)
    link.click()
    link.remove();
    
    }

    setTimeout(()=>{
        navigate("/users")
    })

    downloadFile();
   },[])
    
  return (
    <div className='flex text-center justify-center'>your file will be downloaded soon....</div>
  )
}

export default ExcelFile