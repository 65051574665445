import React from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import UserTable from "../components/UserTable";


function Users() {
  return (
    <div className="flex">
      <div className="w-1/6">
        {" "}
        {/* Adjust the width as needed */}
        <Sidebar />
      </div>

      <div className="flex flex-grow relative flex-col">
        {" "}
        {/* Allow the Navbar to take remaining width */}
  
        <div id="PageContent" className="pt-30 mt-10">
          <div id="PageContent" className="overflow-y-auto overflow-x-hidden ">
            <UserTable />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Users;
