
import { createContext, useState, useContext } from "react";

const UserCredentialsContext = createContext(null);

export const UserCredentialsProvider = ({ children }) => {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const saveCredentials = (email, password) => {
    setCredentials({ email, password });
  };

  return (
    <UserCredentialsContext.Provider value={{ credentials, saveCredentials }}>
      {children}
    </UserCredentialsContext.Provider>
  );
};

export const useUserCredentials = () => useContext(UserCredentialsContext);
