import React from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import DashBoardTable from "../components/DashBoardTable";

function DashBoard() {
  return (
    <div className="flex">
      <div className="w-1/6">
        {" "}
        {/* Adjust the width as needed */}
        <Sidebar />
      </div>

      <div className="flex flex-grow relative flex-col">
        {/* Allow the Navbar to take remaining width */}
        <Navbar/>
   

        <div id="PageContent" className="pt-30 mt-10">
          <div id="PageContent" className="overflow-y-auto overflow-x-hidden ">
            <h2 className="text-3xl pt-10 pb-2 px-20 font-bold leading-none text-gray-700">
              DashBoard Table  
            </h2>
            <DashBoardTable />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashBoard;
