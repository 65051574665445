import React, { useState } from "react";
import axios from "axios";
import config from "../config/config";
import { useAuth } from "../utils/auth";
import Modal from "../components/Modal";
import { useNavigate } from "react-router-dom";


const TwoFactorAuth = () => {
    const auth = useAuth();
    const seturl = `${config.apiBaseUrl}twofactor/setup`;
    const verifyurl = `${config.apiBaseUrl}twofactor/verify`;
    const [qrCodeUrl, setQrCodeUrl] = useState("");
    const [secret, setSecret] = useState("");
    const [token, setToken] = useState("");
    const [message, setMessage] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const navigate = useNavigate()

    // Generate the QR Code and secret
    const generate2FA = async () => {
        try {
            const response = await axios.post(seturl, null, {
                // Using POST and null as body
                headers: {
                    authorization: auth.user.token,
                },
            });
            setQrCodeUrl(response.data.qrCodeUrl);
            setSecret(response.data.secret);
            setMessage("Scan the QR code with your authenticator app.");
            setIsModalVisible(true); // Show modal after generating QR code
        } catch (error) {
            console.error("Error generating 2FA:", error);
            setMessage("Failed to generate 2FA.");
        }
    };

    // Verify the TOTP token
    const verify2FA = async () => {
        try {
            const response = await axios.post(
                verifyurl,
                { token },
                {
                    
                    headers: {
                        authorization: auth.user.token,
                    },
                }
            );
            setMessage(response.data.message);
         
            setIsModalVisible(false);
            navigate("/")
        } catch (error) {
            console.error("Error verifying 2FA:", error);
            setMessage("Invalid token. Please try again.");
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-white text-gray-900">
            <h1 className="text-3xl font-bold mb-8">
                Two-Factor Authentication
            </h1>

            <button
                onClick={generate2FA}
                className="px-6 py-3 bg-white text-gray-900 font-semibold rounded-lg hover:bg-gray-200 transition duration-300 ease-in-out border"
            >
                Setup 2FA
            </button>

            {message && <p className="mt-6 text-yellow-300">{message}</p>}

            {/* Modal for QR code and TOTP input */}
            <Modal
                isVisible={isModalVisible}
                onClose={() => setIsModalVisible(false)}
            >
                <div className="text-center">
                    <p className="mb-4">{message}</p>
                    {qrCodeUrl && (
                        <img
                            src={qrCodeUrl}
                            alt="QR Code"
                            className="mb-6 border-4 border-white rounded-lg"
                        />
                    )}
                    <input
                        type="text"
                        placeholder="Enter TOTP"
                        value={token}
                        onChange={(e) => setToken(e.target.value)}
                        className="mb-4 mr-4 p-3 text-blue-700 bg-white rounded-lg border-2 border-white focus:outline-none focus:ring-2 focus:ring-blue-300"
                    />
                    <button
                        onClick={verify2FA}
                        className="px-6 py-3  bg-blue-700 text-white font-semibold rounded-lg hover:bg-blue-600 transition duration-300 ease-in-out"
                    >
                        Verify 2FA
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default TwoFactorAuth;
