import React from 'react'
import AdminList from './AdminList'
import Sidebar from '../components/Sidebar'


const AdminListPage = () => {
  return (
    <div className="flex">
    <div className="w-1/6">
      {" "}
      {/* Adjust the width as needed */}
      <Sidebar />
    </div>

    <div className="flex flex-grow relative flex-col">
    {" "}
      {/* Allow the Navbar to take remaining width */}

      <div id="PageContent" className="pt-30">
        <div id="PageContent" className="overflow-y-auto overflow-x-hidden ">
        <h2 className="text-3xl pt-10 pb-2 px-20 font-bold leading-none text-gray-700">
              Admin List
            </h2>
          <AdminList/>
        </div>
      </div>
    </div>
  </div>
  )
}

export default AdminListPage