import React, { useState } from "react";
import axios from "axios";
import { useAuth } from "../utils/auth";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import config from "../config/config";
import "react-toastify/dist/ReactToastify.css";
import logo from "../PhishInstinct_Logo_black.png";

const LOGIN_URL = `${config.apiBaseUrl}login/`;


function Login() {
    const [rememberMe, setRememberMe] = useState(false);
    const [user, setUser] = useState({
        email: "",
        password: "",
    });
   
    const [isLockedOut, setIsLockedOut] = useState(false);
    const [twoFactorToken, setTwoFactorToken] = useState(""); // State for 2FA token
    const [twoFactorRequired, setTwoFactorRequired] = useState(false); // To display 2FA input

    const toggleRememberMe = () => setRememberMe(!rememberMe);
    const auth = useAuth();
    const navigate = useNavigate();

    const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

   

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const email = user.email;

        axios({
            method: "post",
            url: LOGIN_URL,
            data: { ...user, token: twoFactorToken },
        })
            .then((response) => {
                const token = response.data.token;
                const userinfo = {
                    token: token,
                    username: email,
                    id: response.data.id,
                };
                auth.login(userinfo, rememberMe);
                navigate("/");
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        setIsLockedOut(true);
                        toast.error(
                            "Account locked due to multiple failed login attempts. Please try again later."
                        );
                    } else if (
                        error.response.status === 400 &&
                        error.response.data.message.includes("2FA")
                    ) {
                        setTwoFactorRequired(true);
                        toast.info(
                            "2FA required. Please enter your 2FA token."
                        );
                    } else {
                        toast.error("Invalid credentials! \n" + error.message);
                    }
                } else if (error.request) {
                    toast.error(
                        "Request was made, but no response received. \n" + error
                    );
                } else {
                    toast.error("Error: \n" + error.message);
                }
            });
    };

    return (
        <div className="flex h-screen bg-white">
            <div className="w-1/2 flex justify-center items-center py-4">
                <div className="ml-10 mr-0 max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-center items-center">
                        <a
                            href="/"
                            className="flex justify-center items-center"
                        >
                            <img
                                src="./login.gif"
                                className=""
                                alt="login page image"
                            />
                        </a>
                    </div>
                </div>
            </div>

            <div className="w-1/2 flex justify-center items-center py-12">
                <div className="max-w-md w-full backdrop-blur-sm bg-white rounded-md shadow-md py-8 px-8 bg-neomorphism-gradient p-6 rounded-lg shadow-neomorphism border border-transparen">
                    <div className="flex flex-col items-center justify-center">
                        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                            <img
                                className="mx-auto h-16 w-auto"
                                src={logo}
                                alt="Phishinstinct"
                            />
                            <h2 className="mt-6 text-center text-2xl font-semibold leading-9 text-gray-900">
                                Log in to your account
                            </h2>
                        </div>
                        <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
                            <form onSubmit={handleSubmit} className="space-y-6">
                                <div>
                                    <label
                                        htmlFor="email"
                                        className="block text-lg font-semibold leading-6 text-gray-900"
                                    >
                                        Email address
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            onChange={handleChange}
                                            placeholder="name@company.com"
                                            autoComplete="email"
                                            required
                                            disabled={isLockedOut}
                                            className="block w-full rounded-md border-2 p-2 text-gray-900 focus:ring-2 focus:ring-primary-300 placeholder:text-gray-400 focus:outline-none focus:border-primary-500 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label
                                        htmlFor="password"
                                        className="block text-lg font-semibold leading-6 text-gray-900"
                                    >
                                        Password
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="password"
                                            name="password"
                                            type="password"
                                            onChange={handleChange}
                                            placeholder="••••••••"
                                            autoComplete="current-password"
                                            required
                                            disabled={isLockedOut}
                                            className="block w-full rounded-md border-2 p-2 text-gray-900 focus:ring-2 focus:ring-primary-300 placeholder:text-gray-400 focus:outline-none focus:border-primary-500 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                {twoFactorRequired && (
                                    <div>
                                        <label
                                            htmlFor="twoFactorToken"
                                            className="block text-lg font-semibold leading-6 text-gray-900"
                                        >
                                            Authenticator Token
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="twoFactorToken"
                                                name="twoFactorToken"
                                                type="text"
                                                onChange={(e) =>
                                                    setTwoFactorToken(
                                                        e.target.value
                                                    )
                                                }
                                                placeholder="Enter your 2FA token"
                                                autoComplete="one-time-code"
                                                required
                                                className="block w-full rounded-md border-2 p-2 text-gray-900 focus:ring-2 focus:ring-primary-300 placeholder:text-gray-400 focus:outline-none focus:border-primary-500 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="flex items-start">
                                    <input
                                        id="remember"
                                        aria-describedby="remember"
                                        type="checkbox"
                                        checked={rememberMe}
                                        onChange={toggleRememberMe}
                                        disabled={isLockedOut}
                                        className="mt-1 w-5 h-5 border border-gray-500 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                                    />
                                    <label
                                        htmlFor="remember"
                                        className="ml-3 text-lg text-black"
                                    >
                                        Remember me
                                    </label>
                                </div>
                              
                                <div>
                                    <button
                                        type="submit"
                                        disabled={isLockedOut}
                                        className="w-full text-white bg-blue-700 hover:bg-[#3ABEF9] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-md px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                                    >
                                        Sign in
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
}

export default Login;
